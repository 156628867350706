.App {
  font-family: L;
  text-align: center;
}

.buttonStyle {
  background: "red";
}

.cat-btn {
  display: none;
  margin: 0;
  position:fixed;
  top: 10px;
  right: 135px;
  padding: 10px;
  color: black;
  cursor: pointer;
  z-index: 100;
  font-size: 16px;
  color: #FFFFFF;
  transition: all .1s ease;
}

.dark-btn {
  right: 10px;
  left: unset;
}

.cat-image {
  display: none;
  position: fixed; 
  width: 200px;
  z-index: 10000;
  padding: 0;
  margin: 0;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
